export function menuLinksLoadRequest() {
  return {
    type: '@menuLinks/LOAD_REQUEST',
  };
}

export function menuLinksLoadSuccess(data) {
  return {
    type: '@menuLinks/LOAD_SUCCESS',
    menuLinks: data,
  };
}

export function menuLinksFailure(error) {
  return {
    type: '@menuLinks/LOAD_FAILURE',
    error,
  };
}
