import INITIAL_STATE from './initialState';
import {
  normalizeInfo,
  normalizeOption,
  normalizeSelectSource,
  normalizeSelectedOption,
  normalizeSelectedCustomOption,
  normalizeRemoveSelectionPart,
  normalizeQtyList,
  addCustomQty,
} from './normalize';

const settings = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case '@settings/RESET':
      return {
        ...INITIAL_STATE,
      };

    // PRODUCT
    case '@settings/PRODUCT_FETCH_REQUEST':
      return {
        ...INITIAL_STATE,
        isRunning: true,
      };

    case '@settings/PRODUCT_FETCH_SUCCESS':
      return normalizeInfo(state, action.payload);

    case '@settings/PRODUCT_FETCH_FAILURE':
      return {
        ...state,
        isRunning: false,
        hasError: true,
        error: action.payload.error,
      };

    // SOURCE
    case '@settings/SOURCE_FETCH_REQUEST':
      return {
        ...state,
        settings: {
          ...state.settings,
          source: {
            ...state.settings.source,
            selectedSource: action.payload.source,
          },
        },
        source: {
          ...state.source,
          isRunning: true,
          isLoaded: false,
          selectedSource: action.payload.source,
        },
        defaultCombinations: {
          ...state.defaultCombinations,
          selectedCombination: action.payload.combination,
          isSelectedCombination: true,
        },
        options: {
          ...INITIAL_STATE.options,
          isRunning: true,
          isLoaded: false,
        },
        matrix: INITIAL_STATE.matrix,
      };

    case '@settings/SOURCE_FETCH_SUCCESS':
      return normalizeOption(state, action.payload);

    case '@settings/SOURCE_FETCH_FAILURE':
      return {
        ...state,
        hasError: true,
        error: action.payload.error,
        source: {
          ...state.source,
          isRunning: false,
        },
        options: {
          ...state.options,
          isRunning: false,
          isLoaded: true,
        },
      };

    case '@settings/SOURCE_SELECT':
      return normalizeSelectSource(state, action.payload);

    // OPTIONS
    case '@settings/OPTIONS_FETCH_REQUEST':
      return {
        ...state,
        options: {
          ...state.options,
          selectionIsComplete: false,
          isLoadingSettings: true,
        },
        defaultCombinations: {
          ...INITIAL_STATE.defaultCombinations,
          enabledCombinations: state.defaultCombinations.enabledCombinations,
          selectedCombination: 'custom',
          isSelectedCombination: true,
          isRunning: false,
          isLoaded: true,
        },
        matrix: INITIAL_STATE.matrix,
        selection: action.payload.selection,
      };
    case '@settings/OPTIONS_FETCH_SUCCESS':
      return normalizeSelectedOption(state, action.payload);

    case '@settings/OPTIONS_FETCH_FAILURE':
      return {
        ...state,
        isRunning: false,
        options: {
          ...state.options,
          isLoadingSettings: false,
          optionsFetchError: action.payload.error,
        },
      };

    case '@settings/OPTIONS_FETCH_RESET':
      return {
        ...state,
        options: {
          ...state.options,
          optionsFetchError: '',
        },
      };

    case '@settings/OPTIONS_UPDATE':
      return {
        ...state,
        options: {
          ...state.options,
          selectionIsComplete: false,
          isLoadingSettings: true,
        },
        defaultCombinations: {
          ...INITIAL_STATE.defaultCombinations,
          enabledCombinations: state.defaultCombinations.enabledCombinations,
          selectedCombination: 'custom',
          isSelectedCombination: true,
          isRunning: false,
          isLoaded: true,
        },
        matrix: INITIAL_STATE.matrix,
        selection: action.payload.selection,
      };

    // ZIPCODE
    case '@settings/ZIPCODE_FETCH_REQUEST':
      return {
        ...state,
        config: {
          ...state.config,
          zipcode: {
            ...state.config.zipcode,
            value: action.payload.zipcode,
            isZipcodeValid: false,
            usePickupPlaces: false,
            isRunning: true,
            isLoaded: false,
            error: '',
          },
        },
        matrix: INITIAL_STATE.matrix,
      };
    case '@settings/ZIPCODE_FETCH_SUCCESS':
      return {
        ...state,
        config: {
          ...state.config,
          zipcode: {
            ...state.config.zipcode,
            ...action.payload,
            isZipcodeValid: true,
            isRunning: false,
            isLoaded: true,
            zipcodeErrorMessage: '',
          },
        },
      };

    case '@settings/ZIPCODE_FETCH_FAILURE':
      return {
        ...state,
        config: {
          ...state.config,
          zipcode: {
            ...state.config.zipcode,
            isRunning: false,
            isZipcodeValid: false,
            zipcodeErrorMessage: action.payload.error,
          },
        },
      };

    case '@settings/ZIPCODE_RESET':
      return {
        ...state,
        config: {
          ...state.config,
          zipcode: INITIAL_STATE.config.zipcode,
        },
        matrix: INITIAL_STATE.matrix,
      };

    // MATRIX
    case '@settings/MATRIX_RESET':
      return {
        ...state,
        matrix: {
          ...INITIAL_STATE.matrix,
          deliveryMethod: state.globalDeliveryMethod,
        },
      };

    case '@settings/MATRIX_FETCH_REQUEST':
      return {
        ...state,
        config: {
          ...state.config,
          zipcode: {
            ...state.config.zipcode,
            ...(action.payload.pickupPlaceId
              ? { pickupPlaceZipcode: action.payload.zipcode, pickupPlaceId: action.payload.pickupPlaceId }
              : { value: state.config.zipcode.value }),
            usePickupPlaces: !!action.payload.pickupPlaceId,
          },
        },
        matrix: {
          ...state.matrix,
          isRunning: true,
          isLoaded: false,
          error: '',
        },
      };

    case '@settings/MATRIX_FETCH_SUCCESS':
      return {
        ...state,
        matrix: {
          ...state.matrix,
          rows: action.payload.rows,
          dates: action.payload.dates,
          isRunning: false,
          isLoaded: true,
        },
        additionalOptions: action.payload.additional_options,
      };

    case '@settings/MATRIX_FETCH_FAILURE':
      return {
        ...state,
        matrix: {
          ...state.matrix,
          isRunning: false,
          isLoaded: false,
          error: action.payload.error,
        },
      };

    // CUSTOM MATRIX
    case '@settings/CUSTOM_MATRIX_FETCH_REQUEST':
      return {
        ...state,
        matrix: {
          ...state.matrix,
          customQuantity: {
            ...state.customQuantity,
            isRunning: true,
            isLoaded: false,
          },
        },
      };

    case '@settings/CUSTOM_MATRIX_FETCH_SUCCESS':
      return {
        ...state,
        additionalOptions: action.payload.additional_options,
        config: {
          ...state.config,
          zipcode: {
            ...state.config.zipcode,
            ...(action.payload.pickupPlaceId
              ? { pickupPlaceZipcode: action.payload.zipcode, pickupPlaceId: action.payload.pickupPlaceId }
              : { value: state.config.zipcode.value }),
            usePickupPlaces: !!action.payload.pickupPlaceId,
          },
        },
        matrix: {
          ...state.matrix,
          customQuantity: {
            ...state.matrix.customQuantity,
            rows: action.payload.rows,
            dates: action.payload.dates,
            isLoaded: true,
            isRunning: false,
            quantity: action.payload.quantity,
          },
          selection: {
            date: 0,
            quantity: action.payload.quantity,
            isCustom: true,
          },
        },
      };

    case '@settings/CUSTOM_MATRIX_FETCH_FAILURE':
      return {
        ...state,
        matrix: {
          ...state.matrix,
          customQuantity: INITIAL_STATE.matrix.customQuantity,
          selection: state.matrix.selection.isCustom ? INITIAL_STATE.matrix.selection : state.matrix.selection,
          customFetchError: action.payload.error,
        },
      };

    case '@settings/CUSTOM_MATRIX_FETCH_RESET':
      return {
        ...state,
        matrix: {
          ...state.matrix,
          customFetchError: '',
        },
      };

    case '@settings/CUSTOM_MATRIX_RESET':
      return {
        ...state,
        matrix: {
          ...state.matrix,
          customQuantity: INITIAL_STATE.matrix.customQuantity,
          selection: state.matrix.selection.isCustom ? INITIAL_STATE.matrix.selection : state.matrix.selection,
        },
      };

    case '@settings/MATRIX_RESET_SELECTION':
      return {
        ...state,
        matrix: {
          ...state.matrix,
          selection: {
            ...state.matrix.selection,
            date: 0,
          },
        },
      };
    // DELIVERY METHOD
    case '@settings/SET_DELIVERY_METHOD':
      return {
        ...state,
        config: {
          ...state.config,
          zipcode: {
            ...state.config.zipcode,
            pickupPlaceId: 0,
          },
        },
        matrix: {
          ...INITIAL_STATE.matrix,
          deliveryMethod: action.payload.method,
        },
        globalDeliveryMethod: action.payload.method,
      };

    case '@settings/SET_OPTIONS_VIEW':
      return {
        ...state,
        options: {
          ...state.options,
          viewType: action.payload.viewType,
        },
      };

    // CUSOM OPTION
    case '@settings/CUSTOM_OPTION_FETCH_REQUEST':
      return {
        ...state,
        options: {
          ...state.options,
          custom: {
            ...INITIAL_STATE.options.custom,
            isLoaded: false,
            isRunning: true,
            error: '',
          },
        },
      };

    case '@settings/CUSTOM_OPTION_FETCH_SUCCESS':
      return normalizeSelectedCustomOption(state, action.payload);

    case '@settings/CUSTOM_OPTION_FETCH_FAILURE':
      return {
        ...state,
        options: {
          ...state.options,
          custom: {
            ...state.options.custom,
            isLoaded: false,
            isRunning: false,
            error: action.payload.error,
          },
        },
      };

    // DIGITAL PRODUCTS
    case '@settings/ADDITIONAL_OPTIONS_FETCH_REQUEST':
      return {
        ...state,
        matrix: {
          ...state.matrix,
          selection: {
            date: action.payload.date,
            quantity: action.payload.quantity,
            isCustom: action.payload.isCustom || false,
          },
        },
      };

    case '@settings/REMOVE_SELECTION_PART':
      return normalizeRemoveSelectionPart(state, action.payload);

    case '@settings/QTY_SELECT':
      return {
        ...state,
        matrixQty: {
          currentQty: action.payload.qty,
          isCustomQty: action.payload.isCustomQty,
        },
      };

    case '@settings/QTY_LIST_REQUEST':
      return {
        ...state,
        matrixQtyList: {
          ...state.matrixQtyList,
          isRunning: true,
          isLoaded: false,
        },
      };
    case '@settings/QTY_LIST_SUCCESS':
      return {
        ...state,
        matrixQtyList: {
          ...state.matrixQtyList,
          ...normalizeQtyList(state, action.payload.data, action.payload.isCustom),
          isRunning: false,
          isLoaded: true,
        },
      };
    case '@settings/QTY_LIST_FAILURE':
      return {
        ...state,
        matrixQtyList: {
          error: action.payload.error?.data || '',
          ...state.matrixQtyList,
          isRunning: false,
          isLoaded: false,
        },
      };
    case '@settings/CHECK_CUSTOM_QTY_REQUEST':
      return {
        ...state,
        matrix: {
          ...state.matrix,
          customQuantity: {
            ...state.matrix.customQuantity,
          },
        },
        matrixQty: {
          ...state.matrixQty,
        },
        matrixQtyList: {
          ...state.matrixQtyList,
          isUpdating: true,
        },
      };
    case '@settings/CHECK_CUSTOM_QTY_SUCCESS':
      return {
        ...state,
        matrixQtyList: {
          ...state.matrixQtyList,
          ...addCustomQty(state.matrixQtyList, action.payload.data),
          isUpdating: false,
        },
        matrixQty: {
          currentQty: action.payload.data.qty,
          isCustomQty: true,
        },
      };
    case '@settings/CHECK_CUSTOM_QTY_FAILURE':
      return {
        ...state,
        matrixQtyList: {
          ...state.matrixQtyList,
          errorMessage: action.payload.error?.data.message || '',
          isUpdating: false,
        },
        matrix: INITIAL_STATE.matrix,
        matrixQty: INITIAL_STATE.matrixQty,
      };
    case '@settings/QTY_RESET':
      return {
        ...state,
        matrix: INITIAL_STATE.matrix,
        matrixQty: INITIAL_STATE.matrixQty,
      };
    case '@settings/QTY_LIST_RESET':
      return {
        ...state,
        matrixQtyList: {
          ...state.matrixQtyList,
          errorMessage: '',
        },
        matrix: INITIAL_STATE.matrix,
        matrixQty: INITIAL_STATE.matrixQty,
      };
    default:
      return state;
  }
};

export default settings;
