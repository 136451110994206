export function settingsFetch(slug) {
  return {
    type: '@settings/PRODUCT_FETCH_REQUEST',
    payload: {
      slug,
    },
  };
}

export function settingsProductReset() {
  return {
    type: '@settings/RESET',
  };
}

export function settingsSourceFetch(productId, source, combination = 0) {
  return {
    type: '@settings/SOURCE_FETCH_REQUEST',
    payload: {
      productId,
      source,
      combination,
    },
  };
}

export function settingsSourceSelect(id, source) {
  return {
    type: '@settings/SOURCE_SELECT',
    payload: {
      id,
      source,
    },
  };
}

export function settingsOptionsFetch(selection, partId, productId, source) {
  return {
    type: '@settings/OPTIONS_FETCH_REQUEST',
    payload: {
      selection,
      partId,
      productId,
      source,
    },
  };
}

export function settingsUpdateOptions(selection, partId) {
  return {
    type: '@settings/OPTIONS_UPDATE',
    payload: {
      selection,
      partId,
    },
  };
}

export function settingsOptionsReset() {
  return {
    type: '@settings/OPTIONS_FETCH_RESET',
  };
}

export function settingsMatrixZipcodeFetch(zipcode, callback) {
  return {
    type: '@settings/ZIPCODE_FETCH_REQUEST',
    payload: {
      zipcode,
      callback,
    },
  };
}

export function settingsZipcodeReset() {
  return {
    type: '@settings/ZIPCODE_RESET',
  };
}

export function settingsMatrixResetSelection() {
  return {
    type: '@settings/MATRIX_RESET_SELECTION',
  };
}

export function settingsMatrixReset() {
  return {
    type: '@settings/MATRIX_RESET',
  };
}

export function settingsMatrixFetch(zipcode, pickupPlaceId) {
  return {
    type: '@settings/MATRIX_FETCH_REQUEST',
    payload: {
      zipcode,
      pickupPlaceId,
    },
  };
}

export function settingsMatrixCustomFetch(quantity, zipcode, pickupPlaceId) {
  return {
    type: '@settings/CUSTOM_MATRIX_FETCH_REQUEST',
    payload: {
      quantity,
      zipcode,
      pickupPlaceId,
    },
  };
}

export function customMatrixFetchReset() {
  return {
    type: '@settings/CUSTOM_MATRIX_FETCH_RESET',
    payload: {},
  };
}

export function settingsCustomMatrixReset() {
  return {
    type: '@settings/CUSTOM_MATRIX_RESET',
    payload: {},
  };
}

export function settingsCustomOptionsFetch(productPart, attribute, customValues) {
  return {
    type: '@settings/CUSTOM_OPTION_FETCH_REQUEST',
    payload: {
      productPart,
      attribute,
      customValues,
    },
  };
}

export function settingsLoadSuccess(settings) {
  return {
    ...settings,
  };
}

export function settingsFailure(type, error) {
  return {
    type,
    payload: {
      error,
    },
  };
}

export function settingsAdditionalOptionsFetch(date, quantity, isCustom = false) {
  return {
    type: '@settings/ADDITIONAL_OPTIONS_FETCH_REQUEST',
    payload: {
      date,
      quantity,
      isCustom,
    },
  };
}

export function settingsOptionsView(viewType) {
  return {
    type: '@settings/SET_OPTIONS_VIEW',
    payload: {
      viewType,
    },
  };
}

export function settingsSetDeliveryMethod(method) {
  return {
    type: '@settings/SET_DELIVERY_METHOD',
    payload: {
      method,
    },
  };
}

export function removePartSelection(part) {
  return {
    type: '@settings/REMOVE_SELECTION_PART',
    payload: {
      part,
    },
  };
}

export function resetQtySelection() {
  return {
    type: '@settings/QTY_RESET',
  };
}
export function setQtySelection(qty, isCustomQty = false) {
  return {
    type: '@settings/QTY_SELECT',
    payload: {
      qty,
      isCustomQty,
    },
  };
}
export function settingsQtyListFetch(productId, source, content) {
  return {
    type: '@settings/QTY_LIST_REQUEST',
    payload: {
      productId,
      source,
      content,
    },
  };
}

export function settingsQtyListFetchReset() {
  return {
    type: '@settings/QTY_LIST_RESET',
  };
}
export function checkCustomQtyFetch(productId, qty, source, content) {
  return {
    type: '@settings/CHECK_CUSTOM_QTY_REQUEST',
    payload: {
      productId,
      qty,
      source,
      content,
    },
  };
}
