export function menuSummaryCategoriesLoadRequest() {
  return {
    type: '@menuSummaryCategories/LOAD_REQUEST',
  };
}

export function menuSummaryCategoriesLoadSuccess(data) {
  return {
    type: '@menuSummaryCategories/LOAD_SUCCESS',
    summaryCategories: data,
  };
}

export function menuSummaryCategoriesFailure(error) {
  return {
    type: '@menuSummaryCategories/LOAD_FAILURE',
    error,
  };
}
