export function localeLoadRequest() {
  return {
    type: '@locale/LOAD_REQUEST',
  };
}

export function localeLoadSuccess(locale) {
  return {
    type: '@locale/LOAD_SUCCESS',
    payload: {
      locale,
    },
  };
}

export function localeFailure() {
  return {
    type: '@locale/LOAD_FAILURE',
  };
}
