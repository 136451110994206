import { combineReducers } from 'redux';

import account from './account/reducer';
import algolia from './algolia/reducer';
import basicCart from './basicCart/reducer';
import cookieConsentPolicy from './cookieConsentPolicy/reducer';
import customerServiceInfo from './customerServiceInfo/reducer';
import downloadTemplates from './downloadTemplates/reducer';
import footerLinks from './footerLinks/reducer';
import headerBanner from './headerBanner/reducer';
import locale from './locale/reducer';
import menuCategories from './menuCategories/reducer';
import menuLinks from './menuLinks/reducer';
import menuSummaryCategories from './menuSummaryCategories/reducer';
import search from './search/reducer';
import settings from './settings/reducer';

export default combineReducers({
  algolia,
  basicCart,
  cookieConsentPolicy,
  downloadTemplates,
  headerBanner,
  locale,
  menuCategories,
  menuLinks,
  footerLinks,
  customerServiceInfo,
  settings,
  account,
  menuSummaryCategories,
  search,
});
