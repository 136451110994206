import { customApi } from '@utils';
import { call, put, all, takeLatest } from 'redux-saga/effects';

import { menuSummaryCategoriesLoadSuccess, menuSummaryCategoriesFailure } from './actions';

function* fetchMenuSummaryCategories() {
  try {
    const response = yield call(customApi.get, '/v1/menu_categories_summary/header');
    yield put(menuSummaryCategoriesLoadSuccess(response.data));
  } catch (error) {
    yield put(menuSummaryCategoriesFailure(error));
  }
}

export default all([takeLatest('@menuSummaryCategories/LOAD_REQUEST', fetchMenuSummaryCategories)]);
