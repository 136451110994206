import { api } from '@utils';
import { call, put, all, takeLatest } from 'redux-saga/effects';

import { basicCartLoadSuccess, basicCartFailure } from './actions';

function* getBasicCart() {
  try {
    const response = yield call(api.get, '/v2/cart/basic');
    yield put(basicCartLoadSuccess(response.data));
  } catch (error) {
    yield put(basicCartFailure());
  }
}

export default all([takeLatest('@basicCart/LOAD_REQUEST', getBasicCart)]);
