const productSettingsState = {
  isRunning: false,
  isLoaded: false,
  hasError: false,
  isAddingToCart: false,
  error: '',
  config: {
    showSteps: {
      source: false,
      defaultCombinations: false,
      options: false,
      qty: false,
      matrix: false,
      additional_options: false,
    },
    zipcode: {
      isZipcodeValid: false,
      pickupPlaceId: 0,
      pickupPlaceZipcode: '',
      usePickupPlaces: false,
      value: '',
      zipcodeErrorMessage: '',
    },
    pickupPlaces: [],
    isAddingToCart: false,
    cartErrorMessage: '',
  },
  source: {
    enabledSources: {},
    selectedSource: null,
  },
  defaultCombinations: {
    enabledCombinations: null,
    selectedCombination: null,
    isSelectedCombination: false,
  },
  options: {
    viewType: 'photos',
    defaultCombinationCount: 0,
    parts: [],
    removed: [],
    selectionIsComplete: false,
    custom: {
      isLoaded: false,
      isRunning: false,
      error: '',
    },
    isLoadingSettings: false,
    isRunning: false,
    isLoaded: false,
  },
  templates: {
    isRunning: false,
    isLoaded: false,
  },
  calculator: {},
  finalProduct: {},
  optionSectionInfo: {},
  selection: {},
  selection_mobile: {
    customQty: false,
    currentQty: 0,
    currentStep: '',
  },
  isSourceAutoSelected: false,
  matrixQty: {
    currentQty: 0,
    isCustomQty: false,
  },
  matrixQtyList: {
    qtyList: [],
    isLoaded: false,
    isRunning: false,
    isUpdating: false,
    errorMessage: '',
  },
  matrix: {
    rows: {},
    dates: {},
    selection: {
      date: 0,
      quantity: 0,
      isCustom: false,
    },
    customQuantity: {
      rows: {},
      dates: {},
      isReady: false,
      isLoaded: false,
      isRunning: false,
      errorMessage: '',
    },
    deliveryMethod: '',
    isRunning: false,
    isLoaded: false,
  },
  globalDeliveryMethod: '',
  additionalOptions: {},
};

export default productSettingsState;
