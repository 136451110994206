export function customerServiceInfoLoadRequest() {
  return {
    type: '@customerServiceInfo/LOAD_REQUEST',
  };
}

export function customerServiceInfoLoadSuccess(data) {
  return {
    type: '@customerServiceInfo/LOAD_SUCCESS',
    customerServiceInfo: data,
  };
}

export function customerServiceInfoFailure(error) {
  return {
    type: '@customerServiceInfo/LOAD_FAILURE',
    error,
  };
}
