import { api } from '@utils';
import { call, put, all, takeLatest } from 'redux-saga/effects';

import { accountLoadSuccess, accountFailure } from './actions';

function* accountLoyaltyFetchRequest() {
  try {
    const endpoint = '/v2/customers/loyalty_program';
    const response = yield call(api.get, endpoint);
    const account = {
      type: '@account/LOYALTY_FETCH_SUCCESS',
      payload: response.data,
    };
    yield put(accountLoadSuccess(account));
  } catch (error) {
    yield put(accountFailure('@account/LOYALTY_FETCH_FAILURE', { message: 'Algo de errado não está correto' }));
  }
}

export default all([takeLatest('@account/LOYALTY_FETCH_REQUEST', accountLoyaltyFetchRequest)]);
