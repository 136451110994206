// utils/facebookPixel.js
import { useEffect } from 'react';

import { getCookie } from 'cookies-next';
import Router, { useRouter } from 'next/router';

const loadFacebookPixel = (route) => {
  const consent = getCookie('cct_accepted');
  const facebook_script = document.getElementById('facebook_pixel');

  if (typeof window !== 'undefined' && facebook_script === null) {
    const script = document.createElement('script');
    script.id = 'facebook_pixel';
    script.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      // Revoke consent before 'init' is called
      ${consent ? `fbq('consent', 'grant')` : `fbq('consent', 'revoke')`};
      fbq('init', '518548001622921');
      ${consent && `fbq('track', 'PageView')`};
    `;
    document.head.appendChild(script);
  } else {
    facebook_script.remove();
    loadFacebookPixel(route);
  }
};

Router.events.on('routeChangeComplete', (route) => loadFacebookPixel(route));

const FacebookPixel = ({ children }) => {
  const { pathname } = useRouter();

  useEffect(() => {
    if (pathname === '/') {
      loadFacebookPixel();
    }
  }, []);

  return children;
};

export default FacebookPixel;
