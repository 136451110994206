import fp from 'fingerprintjs2';

const keyName = 'webshopFingerprint';
export const getFingerprint = () => window.localStorage.getItem(keyName);

export const setFingerprint = () => {
  let fingerprintId = getFingerprint();

  // If it is already set
  if (fingerprintId) {
    return true;
  }

  setTimeout(() => {
    fp.get((components) => {
      fingerprintId = JSON.stringify({
        hash: fp.x64hash128(components.map((p) => p.value).join(''), 31),
        values: components,
      });
      window.localStorage.setItem(keyName, fingerprintId);
    });
  }, 500);

  return true;
};
