const INITIAL_STATE = {
  banner: false,
  loading: false,
  error: '',
};

const headerBanner = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case '@headerBanner/LOAD_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case '@headerBanner/LOAD_SUCCESS':
      return {
        ...state,
        banner: action.banner,
        loading: false,
      };
    case '@headerBanner/LOAD_FAILURE':
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    default:
      return state;
  }
};

export default headerBanner;
