export function menuCategoriesLoadRequest() {
  return {
    type: '@menuCategories/LOAD_REQUEST',
  };
}

export function menuCategoriesLoadSuccess(data) {
  return {
    type: '@menuCategories/LOAD_SUCCESS',
    categories: data,
  };
}

export function menuCategoriesFailure(error) {
  return {
    type: '@menuCategories/LOAD_FAILURE',
    error,
  };
}
