export function footerLinksLoadRequest() {
  return {
    type: '@footerLinks/LOAD_REQUEST',
  };
}

export function footerLinksLoadSuccess(data) {
  return {
    type: '@footerLinks/LOAD_SUCCESS',
    footerLinks: data,
  };
}

export function footerLinksFailure(error) {
  return {
    type: '@footerLinks/LOAD_FAILURE',
    error,
  };
}
