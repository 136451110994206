import { api, mergeDeep } from '@utils';
import { call, put, all, takeLatest } from 'redux-saga/effects';

import { localeLoadSuccess, localeFailure } from './actions';
import missingLocaleBR from './BR.json';
import missingLocaleUS from './US.json';

const missingLocale = {
  BR: missingLocaleBR,
  US: missingLocaleUS,
};

function* getLocale() {
  try {
    const response = yield call(api.get, '/v2/locale');
    const locale = mergeDeep(response.data, missingLocale[response.data.COUNTRY_CODE]);
    yield put(localeLoadSuccess(locale));
  } catch (error) {
    yield put(localeFailure());
  }
}

export default all([takeLatest('@locale/LOAD_REQUEST', getLocale)]);
