/* eslint-disable */
/**
 * normalize the info object, since we read this to get some info to mount the
 * real steps object, this is a read only part of the state (remove it from
 * shouldComponentUpdate when use the funnel state).
 * because of that we will save in redux as is (but not much) from the api
 * @param {Object} state - the actual funnel state
 * @param {Object} data - the api response
 * @returns {Object} The new modified state to save in redux
 */

export const normalizeInfo = (state, data) => {
  if (!data) return state;

  const enabledSources = Object.keys(data.settings.enabled_sources).filter(
    (source) => source !== 'additional_options' && data.settings.enabled_sources[source],
  );
  const selectedSource = data.settings.autoselect_source || (enabledSources.length === 1 && enabledSources[0]);
  const selectSourceComb = selectedSource === 'art_creation' ? 'upload' : selectedSource;
  const enabledCombinations = data.finalProduct.defaultCombination.filter(
    (combination) => combination.source === selectSourceComb,
  );


  return {
    ...state,
    config: {
      ...state.config,
      showSteps: {
        ...data.settings.show_steps,
        source: data.settings.show_steps.source && enabledSources.length > 1,
      },
      isFulfilled: {
        ...state.config.isFulfilled,
        source: !!data.settings.autoselect_source,
      },
    },
    product: data.product,
    finalProduct: data.finalProduct,
    art_creation_rules: data.art_creation_rules,
    alert_product_message: data.alert_product_message,
    autoselect_source: data.settings.autoselect_source,
    isSourceAutoSelected: !!data.settings.autoselect_source,
    source: {
      ...state.source,
      enabledSources: data.settings.enabled_sources,
      selectedSource: selectedSource,
    },
    defaultCombinations: {
      ...state.defaultCombinations,
      enabledCombinations,
      isSelectedCombination: !!data.settings.autoselect_source,
    },
    settings: {
      showSteps: data.settings.show_steps,
    },
    options: state.options,
    matrix: state.matrix,
    isRunning: false,
    isLoaded: true,
  };
};

/**
 * normalize the option response
 * @param {Object} state - the actual funnel state
 * @param {Object} data - the api response
 * @returns {Object} The new modified state to save in redux
 */
export const normalizeOption = (state, data) => {
  if (!data) return state;
  const selection = getSelection(data.product.parts);

  return {
    ...state,
    source: {
      ...state.source,
      isRunning: false,
      isLoaded: true,
    },
    defaultCombinations: {
      ...state.defaultCombinations,
    },
    options: {
      ...state.options,
      defaultCombinationCount: data.default_combination_count,
      parts: data.product.parts,
      selectionIsComplete: isSelectionComplete(selection),
      isRunning: false,
      isLoaded: true,
    },
    selection,
  };
};

export const normalizeSelectedOption = (state, data) => {
  if (!data) return state;

  const parts = state.options.parts
    .map((part, partKey) => {
      if (part.id !== data.product.parts[partKey].id) {
        return part;
      }
      return {
        ...part,
        attributes: part.attributes.map((attribute) => {
          if (data.product.parts[partKey].attributes.filter(opt => opt.key === attribute.key).length > 0) {
            return {
              ...attribute,
              options: data.product.parts[partKey].attributes.filter(opt => opt.key === attribute.key)[0].options,
            };
          }

          return attribute;
        }),
      };
    });

  const selection = parts.reduce(
    (prevPart, currentPart) => ({
      ...prevPart,
      [currentPart.id]: currentPart.attributes.reduce(
        (prevAttribute, currentAttribute) => ({
          ...prevAttribute,
          [currentAttribute.key]:
            (state.selection &&
              state.selection[currentPart.id] &&
              state.selection[currentPart.id][currentAttribute.key]) ||
            (currentAttribute.options.length === 1 && currentAttribute.options[0].id) ||
            '',
        }),
        {},
      ),
    }),
    {},
  );

  return {
    ...state,
    options: {
      ...state.options,
      parts: parts,
      selectionIsComplete: isSelectionComplete(selection),
      isLoadingSettings: false,
    },
    selection,
  };
};

export const normalizeSelectedCustomOption = (state, data) => {
  if (!data) return state;

  const parts = state.options.parts
    .map((part) => {
      if (part.id !== data.productPart) {
        return part;
      }

      return {
        ...part,
        attributes: part.attributes.map((attribute) => {
          if (attribute.key !== data.attribute) {
            return attribute;
          }

          return {
            ...attribute,
            options: attribute.options.concat({
              ...data.customOption,
              imageSmall: '/assets/final/img/imagem-indisponivel-semtexto2.jpg',
              imageBig: '/assets/final/img/imagem-indisponivel-semtexto2.jpg',
              position: Object.keys(attribute.options).length,
            }),
          };
        }),
      };
    });

  return {
    ...state,
    options: {
      ...state.options,
      parts,
      custom: {
        isLoaded: true,
        isRunning: false,
      },
      isLoadingSettings: false,
    },
    selection: {
      ...state.selection,
      [data.productPart]: {
        ...state.selection[data.productPart],
        [data.attribute]: data.customOption.id,
      },
    },
  };
}
/**
 * Generate selection based on the parts.
 * @param {Array} parts
 * @returns {Object}
 */
export const getSelection = (parts = []) => {
  const getDefaultOption = (options) => {
    const defaultOption = options.filter((option) => option.default);
    return defaultOption.length === 1 && defaultOption[0].id;
  };

  return parts.reduce(
    (prevPart, currentPart) => ({
      ...prevPart,
      [currentPart.id]: currentPart.attributes.reduce(
        (prevAttribute, currentAttribute) => ({
          ...prevAttribute,
          [currentAttribute.key]: getDefaultOption(currentAttribute.options) || '',
        }),
        {},
      ),
    }),
    {},
  );
};

export const normalizeSelectSource = (state, data) => {
  if (!data) return state;
  const selectSourceComb = data.source === 'art_creation' ? 'upload' : data.source;
  const enabledCombinations = state.finalProduct.defaultCombination.filter(
    (combination) => combination.source === selectSourceComb,
  );

  return {
    ...state,
    source: {
      ...state.source,
      selectedSource: data.source,
      isRunning: false,
      isLoaded: true,
    },
    defaultCombinations: {
      ...state.defaultCombinations,
      enabledCombinations,
    },
    options: {
      ...state.options,
    },
  };
};

export function isPartComplete(part) {
  return Object.values(part).every((val) => val.trim() !== '');
}

/**
 * Check if selection is complete
 * @param {Array} selection
 * @returns {boolean}
 */

export function isSelectionComplete(selection) {
  return Object.values(selection).every(isPartComplete);
}

export const normalizeRemoveSelectionPart = (state, data) => {
  if (!data) return state;

  const selection = Object.keys(state.selection)
    .filter((part) => part !== data.part)
    .reduce(
      (prevPart, currentPart) => ({
        ...prevPart,
        [currentPart]: state.selection[currentPart],
      }),
      {},
    );

  return {
    ...state,
    options: {
      ...state.options,
      parts: state.options.parts.filter((part) => part.id !== data.part),
      selectionIsComplete: isSelectionComplete(selection),
    },
    selection,
  };
}


export const normalizeQtyList = (state, data, isCustom) => {

  if (!data) return state;

  let qtyList = [];
  let blockedValues = [];
  data.forEach((qtyInfo) => {
    qtyList.push({
      value: parseInt(qtyInfo.qty, 10),
      unit_price: qtyInfo.unit_price,
      label: qtyInfo.qty.toString(),
      price: qtyInfo.price,
      selected: false,
      custom: false,
    });
    blockedValues.push(parseInt(qtyInfo.qty, 10));
  });


  qtyList.sort((a, b) => (a.value > b.value ? 1 : -1));
  return  { qtyList, blockedValues };
};

export const addCustomQty = (state, data) => {
  if (!data) return state;
  state.blockedValues.push(data.qty)
  state.qtyList.push({
    value: data.qty,
    label: data.qty,
    unit_price: data.unit_price,
    price: data.price,
    selected: true,
    custom: true,
  });
  state.qtyList.sort((a, b) => (a.value > b.value ? 1 : -1));
  return state;
}

