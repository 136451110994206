export function downloadTemplatesLoadRequest() {
  return {
    type: '@downloadTemplates/LOAD_REQUEST',
  };
}

export function downloadTemplatesLoadSuccess(data) {
  return {
    type: '@downloadTemplates/LOAD_SUCCESS',
    templates: data,
  };
}

export function downloadTemplatesFailure(data) {
  return {
    type: '@downloadTemplates/LOAD_FAILURE',
    templatesError: data,
  };
}
