export function cookieConsentPolicyLoadRequest() {
  return {
    type: '@cookieConsentPolicy/LOAD_REQUEST',
  };
}

export function cookieConsentPolicyLoadSuccess(data) {
  return {
    type: '@cookieConsentPolicy/LOAD_SUCCESS',
    cookieConsentPolicy: data,
  };
}

export function cookieConsentPolicyFailure(error) {
  return {
    type: '@cookieConsentPolicy/LOAD_FAILURE',
    error,
  };
}
