import { customApi } from '@utils';
import { call, put, all, takeLatest } from 'redux-saga/effects';

import { customerServiceInfoLoadSuccess, customerServiceInfoFailure } from './actions';

function* fetchCustomerServiceInfo() {
  try {
    const { data } = yield call(customApi.get, '/v1/container-contents/customer-service-info');
    yield put(customerServiceInfoLoadSuccess(data.content));
  } catch (error) {
    yield put(customerServiceInfoFailure(error));
  }
}

export default all([takeLatest('@customerServiceInfo/LOAD_REQUEST', fetchCustomerServiceInfo)]);
