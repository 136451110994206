export function basicCartLoadRequest() {
  return {
    type: '@basicCart/LOAD_REQUEST',
  };
}

export function basicCartLoadSuccess(data) {
  return {
    type: '@basicCart/LOAD_SUCCESS',
    payload: {
      data,
    },
  };
}

export function basicCartFailure() {
  return {
    type: '@basicCart/LOAD_FAILURE',
  };
}
