import { customApi } from '@utils';
import { call, put, all, takeLatest } from 'redux-saga/effects';

import { searchStatusLoadSuccess, searchStatusFailure } from './actions';

function* getSearchSettings() {
  try {
    const response = yield call(customApi.get, '/v1/settings_search/status');
    yield put(searchStatusLoadSuccess(response.data));
  } catch (error) {
    yield put(searchStatusFailure());
  }
}

export default all([takeLatest('@searchStatus/LOAD_REQUEST', getSearchSettings)]);
