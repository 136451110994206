export function algoliaSearchStatusLoadRequest() {
  return {
    type: '@algoliaSearchStatus/LOAD_REQUEST',
  };
}

export function algoliaSearchStatusLoadSuccess(data) {
  return {
    type: '@algoliaSearchStatus/LOAD_SUCCESS',
    payload: {
      data,
    },
  };
}

export function algoliaSearchStatusFailure() {
  return {
    type: '@algoliaSearchStatus/LOAD_FAILURE',
  };
}
