const INITIAL_STATE = {
  templates: null,
  templatesLoading: true,
  templatesError: '',
};

const downloadTemplates = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case '@downloadTemplates/LOAD_REQUEST':
      return {
        ...state,
        templatesLoading: true,
        templatesError: '',
      };
    case '@downloadTemplates/LOAD_SUCCESS':
      return {
        ...state,
        templates: action.templates,
        templatesLoading: false,
      };
    case '@downloadTemplates/LOAD_FAILURE':
      return {
        ...state,
        templatesError: action.templatesError,
        templatesLoading: false,
      };

    default:
      return state;
  }
};

export default downloadTemplates;
