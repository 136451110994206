const INITIAL_STATE = {
  loyalty: {
    isRunning: false,
    isLoaded: false,
  },
};

const account = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case '@account/LOYALTY_FETCH_REQUEST':
      return {
        ...state,
        loyalty: {
          isRunning: true,
          isLoaded: false,
        },
      };
    case '@account/LOYALTY_FETCH_SUCCESS':
      return {
        ...state,
        loyalty: {
          ...action.payload,
          isRunning: false,
          isLoaded: true,
        },
      };
    case '@account/LOYALTY_FETCH_FAILURE':
      return {
        ...state,
        loyalty: {
          isRunning: false,
          isLoaded: true,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};

export default account;
