const INITIAL_STATE = {
  count: 0,
  zipcode: '',
  use_pickup_places: false,
  pickup_place_id: 0,
  loading: false,
  error: '',
};

const basicCart = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case '@basicCart/LOAD_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case '@basicCart/LOAD_SUCCESS':
      return {
        ...state,
        ...action.payload.data,
        loading: false,
      };
    case '@basicCart/LOAD_FAILURE':
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    default:
      return state;
  }
};

export default basicCart;
