const formatMoney = (number) => {
  let newNumber = number;
  if (typeof number !== 'string' && typeof number !== 'number') {
    newNumber = 0;
  }

  if (typeof number === 'string') {
    newNumber = parseFloat(number.replace(/,/g, '.'));
  }

  if (number === '') {
    newNumber = 0;
  }

  return new Intl.NumberFormat('pt-br', {
    style: 'currency',
    currency: 'BRL',
  })
    .format(newNumber)
    .replace('\xa0', ' ');
};

export default formatMoney;
