import { all } from 'redux-saga/effects';

import account from './account/sagas';
import algolia from './algolia/sagas';
import basicCart from './basicCart/sagas';
import cookieConsentPolicy from './cookieConsentPolicy/sagas';
import customerServiceInfo from './customerServiceInfo/sagas';
import downloadTemplates from './downloadTemplates/sagas';
import footerLinks from './footerLinks/sagas';
import headerBanner from './headerBanner/sagas';
import locale from './locale/sagas';
import menuCategories from './menuCategories/sagas';
import menuLinks from './menuLinks/sagas';
import menuSummaryCategories from './menuSummaryCategories/sagas';
import search from './search/sagas';
import settings from './settings/sagas';

export default function* rootSaga() {
  return yield all([
    basicCart,
    algolia,
    cookieConsentPolicy,
    downloadTemplates,
    headerBanner,
    locale,
    menuCategories,
    menuLinks,
    footerLinks,
    customerServiceInfo,
    settings,
    account,
    menuSummaryCategories,
    search,
  ]);
}
