import { customApi } from '@utils';
import { call, put, all, takeLatest } from 'redux-saga/effects';

import { menuLinksLoadSuccess, menuLinksFailure } from './actions';

function* fetchMenuLinks() {
  try {
    const response = yield call(customApi, '/v1/menu_links');
    yield put(menuLinksLoadSuccess(response.data));
  } catch (error) {
    yield put(menuLinksFailure(error));
  }
}

export default all([takeLatest('@menuLinks/LOAD_REQUEST', fetchMenuLinks)]);
