import { customApi, api, nUpdateSelection, isSelectionComplete } from '@utils';
import { select, call, put, all, takeLatest } from 'redux-saga/effects';

import { settingsLoadSuccess, settingsFailure, settingsUpdateOptions } from './actions';

const errorList = {
  'No Product Data': 'Nosso banco de dados não possui informações deste produto, por favor, tente novamente.',
  NO_DATA_FOUND_CEP: 'Por favor, escolha outro CEP',
  NO_DATA_FOUND_PICKUPPLACE: 'Não é possível retirar este produto neste ponto de retirada, por favor selecione outro',
  productFetch: 'Houve um erro ao carregar as informações de configuração do produto, por favor, tente novamente.',
  sourceFetch: 'As opções do produto não carregaram corretamente, por favor, tente novamente.',
  zipcodeFetch: 'CEP inválido, por favor, tente outro',
  matrixFetch: 'Houve um erro ao carregar as opções de preço e entrega deste produto, por favor, tente novamente.',
  'error.calculator.FINAL_PRODUCT_NOT_FOUND':
    'Não encontramos as opções de preço e entrega deste produto, por favor, tente novamente.',
  matrixCustomFetch: 'Houve um erro ao calcular as opções de preço e entrega, por favor, tente novamente.',
  optionsFetch: 'Houve um erro ao carregar as opções deste produto, por favor, tente novamente.',
  customOptionsFetch: 'Houve um erro ao carregar personalizar o formato, por favor, tente novamente.',
};

function* settingsFetch(action) {
  try {
    const { data } = yield call(customApi.get, `/v1/product/${action.payload.slug}/settings`);
    const settings = {
      type: '@settings/PRODUCT_FETCH_SUCCESS',
      payload: {
        ...data,
      },
    };

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'gaEvent',
      eventCategory: 'SettingsPage',
      eventAction: data.finalProduct.id,
      eventLabel: 'View',
      eventValue: 0,
      eventNonInteraction: 1,
    });

    yield put(settingsLoadSuccess(settings));
  } catch (error) {
    const { data } = error.response;
    const errorResponse = errorList[data] || '404';

    if (errorResponse === '404') {
      window.location.href = '/404'; // Redireciona para a página 404
    } else {
      yield put(settingsFailure('@settings/PRODUCT_FETCH_FAILURE', errorResponse));
    }
  }
}

function* settingsSourceFetch(action) {
  try {
    let endpoint = `/v1/calculator/finalproducts/${action.payload.productId}/source/${action.payload.source}`;
    if (action.payload.combination !== undefined || action.payload.combination !== null) {
      endpoint = `/v1/calculator/finalproducts/${action.payload.productId}/source/${action.payload.source}/position/${action.payload.combination}`;
    }
    const response = yield call(customApi.get, endpoint);
    const settings = {
      type: '@settings/SOURCE_FETCH_SUCCESS',
      payload: {
        ...response.data,
      },
    };

    yield put(settingsLoadSuccess(settings));
  } catch (error) {
    const { data } = error.response;
    const errorResponse = errorList[data] || errorList.productFetch;
    yield put(settingsFailure('@settings/SOURCE_FETCH_FAILURE', errorResponse));
  }
}

function* settingsOptionsFetch(action) {
  try {
    const endpoint = `/v1/calculator/finalproducts/${action.payload.productId}/deny_rules/source/${action.payload.source.selectedSource}`;
    const body = {
      id: action.payload.partId,
      type: 'product_part',
      selection: Object.keys(action.payload.selection).reduce(
        (prevPart, currentPart) => ({
          ...prevPart,
          [currentPart]: Object.keys(action.payload.selection[currentPart])
            .filter((attribute) => action.payload.selection[currentPart][attribute] !== '')
            .reduce(
              (prevAttribute, currentAttribute) => ({
                ...prevAttribute,
                [currentAttribute]: action.payload.selection[currentPart][currentAttribute],
              }),
              {},
            ),
        }),
        {},
      ),
    };

    const response = yield call(customApi.post, endpoint, body);
    if (response.data === 'NO_DATA_FOUND') {
      yield put(settingsFailure('@settings/OPTIONS_FETCH_FAILURE', errorList.optionsFetch));
    } else {
      const settings = {
        type: '@settings/OPTIONS_FETCH_SUCCESS',
        payload: {
          ...response.data,
          partId: action.payload.partId,
          selection: action.payload.selection,
        },
      };

      yield put(settingsLoadSuccess(settings));
    }
  } catch (error) {
    const { data } = error.response;
    const errorResponse = errorList[data] || errorList.optionsFetch;
    yield put(settingsFailure('@settings/OPTIONS_FETCH_FAILURE', errorResponse));
  }
}

function* settingsMatrixZipcodeFetch(action) {
  try {
    const response = yield call(customApi.get, `/v1/zipcode/${action.payload.zipcode}`);

    if (!response.data) {
      throw response;
    }

    const settings = {
      type: '@settings/ZIPCODE_FETCH_SUCCESS',
      payload: {
        ...response.data,
      },
    };

    yield put(settingsLoadSuccess(settings));

    if (action.payload.callback !== undefined) {
      action.payload.callback(action.payload.zipcode);
    }
  } catch (error) {
    const { message } = error;
    const errorResponse = errorList[message] || errorList.zipcodeFetch;
    yield put(settingsFailure('@settings/ZIPCODE_FETCH_FAILURE', errorResponse));
  }
}

function* settingsMatrixFetch(action) {
  try {
    const prodSettings = (state) => state.settings;
    const productSettings = yield select(prodSettings);

    if (!productSettings || !productSettings.finalProduct.id || !isSelectionComplete(productSettings.selection)) return;

    const endpoint = `/v1/calculator/finalproducts/${productSettings.finalProduct.id}/matrix`;

    const body = {
      combination: productSettings.selection,
      source: productSettings.source.selectedSource,
      zipcode: action.payload.zipcode,
      pickup_place_id: action.payload.pickupPlaceId,
    };

    if (productSettings.matrixQty.currentQty && productSettings.matrixQty.currentQty > 0) {
      body.quantity = productSettings.matrixQty.currentQty;
    }

    const response = yield call(api.post, endpoint, body);

    const settings = {
      type: '@settings/MATRIX_FETCH_SUCCESS',
      payload: {
        ...response.data,
      },
    };
    yield put(settingsLoadSuccess(settings));
  } catch (error) {
    let { data } = error.response;

    if (data === 'NO_DATA_FOUND' && action.payload.pickupPlaceId) {
      data = 'NO_DATA_FOUND_PICKUPPLACE';
    }

    if (data === 'NO_DATA_FOUND' && !action.payload.pickupPlaceId) {
      data = 'NO_DATA_FOUND_CEP';
    }

    const errorResponse = errorList[data] || errorList.matrixFetch;
    yield put(settingsFailure('@settings/MATRIX_FETCH_FAILURE', errorResponse));
  }
}
function* settingsMatrixCustomFetch(action) {
  try {
    const prodSettings = (state) => state.settings;
    const productSettings = yield select(prodSettings);
    if (!productSettings || !productSettings.finalProduct.id || !isSelectionComplete(productSettings.selection)) return;
    const endpoint = `/v1/calculator/finalproducts/${productSettings.finalProduct.id}/matrix/custom_qty/${action.payload.quantity}/${productSettings.source.selectedSource}`;
    let zipcode = action.payload.zipcode || productSettings.config.zipcode.value;
    let pickupPlace = action.payload.pickupPlaceId || '';
    if (['freePickup', 'pickupPlaces'].indexOf(productSettings.matrix.deliveryMethod) > -1) {
      zipcode = action.payload.zipcode || productSettings.config.zipcode.pickupPlaceZipcode;
      pickupPlace = pickupPlace || productSettings.config.zipcode.pickupPlaceId;
    }
    const body = {
      combination: productSettings.selection,
      pickup_place_id: pickupPlace,
      source: productSettings.source.selectedSource,
      zipcode,
    };
    const response = yield call(api.post, endpoint, body);
    const settings = {
      type: '@settings/CUSTOM_MATRIX_FETCH_SUCCESS',
      payload: {
        pickupPlaceId: pickupPlace,
        zipcode,
        ...response.data,
        quantity: action.payload.quantity,
      },
    };
    yield put(settingsLoadSuccess(settings));
  } catch (error) {
    const { data } = error.response;
    const errorResponse = data.message || errorList.matrixCustomFetch;
    yield put(settingsFailure('@settings/CUSTOM_MATRIX_FETCH_FAILURE', errorResponse));
  }
}

function* settingsAdditionalOptionsFetch(action) {
  try {
    const prodSettings = (state) => state.settings;
    const productSettings = yield select(prodSettings);
    const endpoint = `/v1/calculator/finalproducts/${productSettings.finalProduct.id}/deny_rules_additional_options/source/${productSettings.source.selectedSource}`;
    const deliveryMethod = action.payload.isCustom
      ? productSettings.matrix.customQuantity.rows[action.payload.quantity][action.payload.date]
      : productSettings.matrix.rows[action.payload.quantity][action.payload.date];

    const body = {
      additional_options: {
        ...productSettings.additionalOptions,
        delivery_method: [deliveryMethod.delivery_method],
        production_type: [deliveryMethod.production_type],
      },
      data: {
        customQuantity: 0,
        qty: action.payload.quantity,
        product_parts: productSettings.selection,
      },
      selection: {
        delivery_method: deliveryMethod.delivery_method,
        production_type: deliveryMethod.production_type.id,
        ...Object.keys(productSettings.additionalOptions)
          .filter((additionalOption) => productSettings.additionalOptions[additionalOption].length > 0)
          .reduce(
            (prevOption, currentOption) => ({
              ...prevOption,
              [currentOption]: productSettings.additionalOptions[currentOption]
                .filter((additionalOption) => additionalOption.price === 0)
                .reduce((prevAdditionalOption, currentAdditionalOption) => currentAdditionalOption.id, ''),
            }),
            {},
          ),
      },
      type: 'product_part',
    };
    const response = yield call(customApi.post, endpoint, body);
    const settings = {
      type: '@settings/ADDITIONAL_OPTIONS_FETCH_SUCCESS',
      payload: {
        ...response.data,
        partId: action.payload.partId,
        selection: action.payload.selection,
      },
    };
    yield put(settingsLoadSuccess(settings));
    action.payload.callback(action.payload.zipcode);
  } catch (error) {
    yield put(settingsFailure('@settings/ADDITIONAL_OPTIONS_FETCH_FAILURE'));
  }
}

function* settingsCustomOptionsFetch(action) {
  try {
    const endpoint = `/v1/calculator/productparts/${action.payload.productPart}/custom_${action.payload.attribute}/${action.payload.customValues}`;
    const response = yield call(customApi.get, endpoint);
    const prodSettings = (state) => state.settings;
    const productSettings = yield select(prodSettings);

    yield put(
      settingsUpdateOptions(
        nUpdateSelection(
          productSettings.selection,
          action.payload.productPart,
          action.payload.attribute,
          response.data.id,
        ),
        action.payload.productPart,
      ),
    );

    const settings = {
      type: '@settings/CUSTOM_OPTION_FETCH_SUCCESS',
      payload: {
        attribute: action.payload.attribute,
        productPart: action.payload.productPart,
        customOption: response.data,
      },
    };

    yield put(settingsLoadSuccess(settings));
  } catch (error) {
    const { data } = error.response;
    const errorResponse = errorList[data] || errorList.customOptionsFetch;
    yield put(settingsFailure('@settings/CUSTOM_OPTION_FETCH_FAILURE', errorResponse));
  }
}

function* fetchQtyList(action) {
  try {
    const prodSettings = (state) => state.settings;
    const productSettings = yield select(prodSettings);
    const endpoint = `/v1/calculator/finalproducts/${action.payload.productId}/source/${action.payload.source}/quantities`;
    const response = yield call(customApi.post, endpoint, JSON.stringify(action.payload.content));
    const qty = {
      type: '@settings/QTY_LIST_SUCCESS',
      payload: {
        data: response.data,
        isCustom: productSettings.finalProduct.custom_qty === '1',
      },
    };
    yield put(settingsLoadSuccess(qty));
  } catch (error) {
    const { response } = error;
    yield put(settingsFailure('@settings/QTY_LIST_FAILURE', response));
  }
}

function* checkCustomQtyFetch(action) {
  try {
    const endpoint = `/v1/calculator/finalproducts/${action.payload.productId}/source/${action.payload.source}/custom_qty/${action.payload.qty}`;
    const response = yield call(customApi.post, endpoint, JSON.stringify(action.payload.content));
    const qty = {
      type: '@settings/CHECK_CUSTOM_QTY_SUCCESS',
      payload: {
        data: response.data,
      },
    };
    yield put(settingsLoadSuccess(qty));
  } catch (error) {
    const { response } = error;
    yield put(settingsFailure('@settings/CHECK_CUSTOM_QTY_FAILURE', response));
  }
}

export default all([
  takeLatest('@settings/PRODUCT_FETCH_REQUEST', settingsFetch),
  takeLatest('@settings/SOURCE_FETCH_REQUEST', settingsSourceFetch),
  takeLatest('@settings/OPTIONS_FETCH_REQUEST', settingsOptionsFetch),
  takeLatest('@settings/ZIPCODE_FETCH_REQUEST', settingsMatrixZipcodeFetch),
  takeLatest('@settings/MATRIX_FETCH_REQUEST', settingsMatrixFetch),
  takeLatest('@settings/CUSTOM_MATRIX_FETCH_REQUEST', settingsMatrixCustomFetch),
  takeLatest('@settings/ADDITIONAL_OPTIONS_FETCH_REQUEST', settingsAdditionalOptionsFetch),
  takeLatest('@settings/CUSTOM_OPTION_FETCH_REQUEST', settingsCustomOptionsFetch),
  takeLatest('@settings/QTY_LIST_REQUEST', fetchQtyList),
  takeLatest('@settings/CHECK_CUSTOM_QTY_REQUEST', checkCustomQtyFetch),
]);
