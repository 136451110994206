import { customApi } from '@utils';
import { call, put, all, takeLatest } from 'redux-saga/effects';

import { menuCategoriesLoadSuccess, menuCategoriesFailure } from './actions';

function* fetchMenuCategories() {
  try {
    const response = yield call(customApi.get, '/v1/menu_categories/header');
    yield put(menuCategoriesLoadSuccess(response.data));
  } catch (error) {
    yield put(menuCategoriesFailure(error));
  }
}

export default all([takeLatest('@menuCategories/LOAD_REQUEST', fetchMenuCategories)]);
