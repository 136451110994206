export function accountLoadSuccess(settings) {
  return {
    ...settings,
  };
}

export function accountFailure(type, error) {
  return {
    type,
    payload: {
      error,
    },
  };
}

export function accountLoyaltyFetchRequest() {
  return {
    type: '@account/LOYALTY_FETCH_REQUEST',
  };
}
