export function searchStatusLoadRequest() {
  return {
    type: '@searchStatus/LOAD_REQUEST',
  };
}

export function searchStatusLoadSuccess(data) {
  return {
    type: '@searchStatus/LOAD_SUCCESS',
    payload: {
      data,
    },
  };
}

export function searchStatusFailure() {
  return {
    type: '@searchStatus/LOAD_FAILURE',
  };
}
