const INITIAL_STATE = {
  status: false,
  loading: false,
  isLoaded: false,
  error: '',
};

const algoliaSearchStatus = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case '@algoliaSearchStatus/LOAD_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case '@algoliaSearchStatus/LOAD_SUCCESS':
      return {
        ...state,
        ...action.payload.data,
        loading: false,
        isLoaded: true,
      };
    case '@algoliaSearchStatus/LOAD_FAILURE':
      return {
        ...state,
        error: action.payload.error,
        loading: false,
        isLoaded: false,
      };

    default:
      return state;
  }
};

export default algoliaSearchStatus;
