const INITIAL_STATE = {
  locale: null,
  localeLoading: false,
  error: '',
};

const locale = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case '@locale/LOAD_REQUEST':
      return {
        ...state,
        localeLoading: true,
      };
    case '@locale/LOAD_SUCCESS':
      return {
        ...state,
        locale: action.payload.locale,
        localeLoading: false,
      };
    case '@locale/LOAD_FAILURE':
      return {
        ...state,
        error: action.payload.error,
        localeLoading: false,
      };

    default:
      return state;
  }
};

export default locale;
