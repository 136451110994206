import { customApi } from '@utils';
import { call, put, all, takeLatest } from 'redux-saga/effects';

import { algoliaSearchStatusLoadSuccess, algoliaSearchStatusFailure } from './actions';

function* getAlogoliaStatus() {
  try {
    const response = yield call(customApi.get, '/v1/alogolia/status');
    yield put(algoliaSearchStatusLoadSuccess(response.data));
  } catch (error) {
    yield put(algoliaSearchStatusFailure());
  }
}

export default all([takeLatest('@algoliaSearchStatus/LOAD_REQUEST', getAlogoliaStatus)]);
