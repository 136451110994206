const INITIAL_STATE = {
  cookieConsentPolicy: undefined,
  loading: false,
  error: '',
};

const cookieConsentPolicy = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case '@cookieConsentPolicy/LOAD_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case '@cookieConsentPolicy/LOAD_SUCCESS':
      return {
        ...state,
        cookieConsentPolicy: action.cookieConsentPolicy,
        loading: false,
      };
    case '@cookieConsentPolicy/LOAD_FAILURE':
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    default:
      return state;
  }
};

export default cookieConsentPolicy;
