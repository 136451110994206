import React, { useEffect, useState, useMemo } from 'react';

import { ComponentsProvider, Loader, theme } from '@printi/printi-components';
import { wrapper } from '@store';
import { getCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import GlobalStyles from '../styles/Global';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import FacebookPixel from '../utils/facebookPixel';

const MyApp = ({ Component, pageProps, err }) => {
  const router = useRouter();
  const [isReady, setIsReady] = useState(false);

  const cookiePreferences = useMemo(
    () => ({ prefAdvertising: false, prefAnalytical: false, prefFunctional: false }),
    [],
  );
  const storePathValues = () => {
    const storage = window.sessionStorage;
    if (!storage) return;
    const prevPath = storage.getItem('currentPath');
    storage.setItem('prevPath', prevPath);
    storage.setItem('currentPath', window.location.pathname);
  };

  const consent = getCookie('cct_accepted');
  const cctInfo = getCookie('cct_information');
  const cctInformation = cctInfo ? JSON.parse(cctInfo) : false;
  cookiePreferences.prefAdvertising = !!cctInformation.pref_advertising;
  cookiePreferences.prefAnalytical = !!cctInformation.pref_analytical;
  cookiePreferences.prefFunctional = !!cctInformation.pref_functional;

  useEffect(() => storePathValues, [router.asPath]);

  useEffect(() => {
    setTimeout(() => setIsReady(true), 100);
  }, []);

  useEffect(() => {
    if (window && typeof window.gtag === 'function') {
      window.gtag('consent', 'update', {
        ad_storage: consent && cookiePreferences.prefAdvertising ? 'granted' : 'denied',
        analytics_storage: consent && cookiePreferences.prefAnalytical ? 'granted' : 'denied',
        functionality_storage: consent && cookiePreferences.prefFunctional ? 'granted' : 'denied',
        personalization_storage: 'granted',
        security_storage: 'granted',
      });
    }
  }, [consent, cookiePreferences]);

  useEffect(() => {
    // Obtenha todos os elementos com a classe ".choice"
    const choiceElements = document.querySelectorAll('.choice');

    // Adicione um ouvinte de eventos a cada elemento ".choice"
    choiceElements.forEach((choiceElement) => {
      choiceElement.addEventListener('click', () => {
        // Encontre o elemento label imediatamente dentro do elemento ".choice" clicado
        const labelElement = document.querySelector('label');

        if (labelElement) {
          // Obtenha o texto do elemento label
          const labelText = labelElement.textContent;
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'userIdentificationGroup',
            userGroup: labelText,
          });

          // Faça um log no console com o valor
          console.log('Texto do label selecionado:', labelText);
        }
      });
    });
  }, []);

  return (
    <FacebookPixel>
      <ComponentsProvider theme={theme}>
        <GlobalStyles />
        {isReady ? <Component {...pageProps} err={err} /> : <Loader size="medium" wrapper="100vh" />}
      </ComponentsProvider>
    </FacebookPixel>
  );
};

MyApp.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  pageProps: PropTypes.object.isRequired,
  err: PropTypes.object,
};

export default wrapper.withRedux(MyApp);
