const INITIAL_STATE = {
  status: { type_search: 'printi' },
  loading: false,
  isLoaded: false,
  error: '',
};

const searchStatus = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case '@searchStatus/LOAD_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case '@searchStatus/LOAD_SUCCESS':
      return {
        ...state,
        status: {
          ...action.payload.data.status,
        },
        loading: false,
        isLoaded: true,
      };
    case '@searchStatus/LOAD_FAILURE':
      return {
        ...state,
        error: action.payload.error,
        loading: false,
        isLoaded: false,
      };

    default:
      return state;
  }
};

export default searchStatus;
