import { customApi } from '@utils';
import { call, put, all, takeLatest } from 'redux-saga/effects';

import { cookieConsentPolicyLoadSuccess, cookieConsentPolicyFailure } from './actions';

function* fetchCookieConsentPolicy() {
  try {
    const response = yield call(customApi, '/v1/cookie-consent/cookie-consent-policy');
    yield put(cookieConsentPolicyLoadSuccess(response.data));
  } catch (error) {
    yield put(cookieConsentPolicyFailure(error));
  }
}

export default all([takeLatest('@cookieConsentPolicy/LOAD_REQUEST', fetchCookieConsentPolicy)]);
