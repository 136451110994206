import { customApi } from '@utils';
import { call, put, all, takeLatest } from 'redux-saga/effects';

import { downloadTemplatesLoadSuccess, downloadTemplatesFailure } from './actions';

function* fetchTemplates() {
  try {
    const response = yield call(customApi.get, '/v1/prepress_template/get_product_list');
    yield put(downloadTemplatesLoadSuccess(response.data));
  } catch (error) {
    yield put(downloadTemplatesFailure(error.response.data || 'Erro ao carregar a lista de productos com template'));
  }
}

export default all([takeLatest('@downloadTemplates/LOAD_REQUEST', fetchTemplates)]);
