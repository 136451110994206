import { customApi } from '@utils';
import { call, put, all, takeLatest } from 'redux-saga/effects';

import { headerBannerLoadSuccess, headerBannerFailure } from './actions';

function* fetchHeaderBanner() {
  try {
    const response = yield call(customApi.get, '/v1/banner-header');
    const { warning_desktop: desktop, warning_mobile: mobile } = response.data;

    yield put(
      headerBannerLoadSuccess({
        mobile,
        desktop,
      }),
    );
  } catch (error) {
    yield put(headerBannerFailure());
  }
}

export default all([takeLatest('@headerBanner/LOAD_REQUEST', fetchHeaderBanner)]);
