const INITIAL_STATE = {
  summaryCategories: undefined,
  loading: false,
  error: '',
};

const menuSummaryCategories = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case '@menuSummaryCategories/LOAD_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case '@menuSummaryCategories/LOAD_SUCCESS':
      return {
        ...state,
        summaryCategories: action.summaryCategories,
        loading: false,
      };
    case '@menuSummaryCategories/LOAD_FAILURE':
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    default:
      return state;
  }
};

export default menuSummaryCategories;
