import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  #renderBadge-load {
    z-index: 1 !important;
  }

  html {
    word-break: keep-all !important;
    scroll-behavior: smooth;
  }

`;
