import jsCookie from 'js-cookie';

const usabilityCookies = ['usrzipcode', 'deliveryMethod', 'pickupPlaceId', 'billing-survey'];

const getCookie = (name, json = false) => (json ? jsCookie.getJSON(name) : jsCookie.get(name));

const setCookie = (name, value, expires = 30, path = '/') => {
  if (!isUsabilityCookiesAllowed(name)) return;

  jsCookie.set(name, value, { expires, path });
};

const removeCookie = (name) => {
  jsCookie.remove(name);
};

const getCookieConsentId = () => getCookie('cct_information', true)?.id;

const isCookieConsentAvailable = () =>
  !getCookie('cct_accepted') ||
  localStorage.getItem('cookiePolicyVersion') !== getCookie('cct_information', true)?.cp_version;

const isUsabilityCookiesAllowed = (name) => !(usabilityCookies.includes(name) && isCookieConsentAvailable());

export { getCookie, setCookie, removeCookie, getCookieConsentId, isCookieConsentAvailable, isUsabilityCookiesAllowed };
