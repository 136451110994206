const INITIAL_STATE = {
  menuLinks: undefined,
  loading: false,
  error: '',
};

const menuLinks = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case '@menuLinks/LOAD_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case '@menuLinks/LOAD_SUCCESS':
      return {
        ...state,
        menuLinks: action.menuLinks,
        loading: false,
      };
    case '@menuLinks/LOAD_FAILURE':
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    default:
      return state;
  }
};

export default menuLinks;
