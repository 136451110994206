export function headerBannerLoadRequest() {
  return {
    type: '@headerBanner/LOAD_REQUEST',
  };
}

export function headerBannerLoadSuccess(data) {
  return {
    type: '@headerBanner/LOAD_SUCCESS',
    banner: data,
  };
}

export function headerBannerFailure(error) {
  return {
    type: '@headerBanner/LOAD_FAILURE',
    error,
  };
}
