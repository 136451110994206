import { customApi } from '@utils';
import { call, put, all, takeLatest } from 'redux-saga/effects';

import { footerLinksLoadSuccess, footerLinksFailure } from './actions';

function reorganizeArray(a, b) {
  if (a.slug < b.slug) return -1;
  if (a.slug > b.slug) return 1;
  return 0;
}

function* fetchFooterLinks() {
  try {
    const response = yield call(customApi.get, '/v1/footer-links');
    response.data.sort((a, b) => reorganizeArray(a, b));
    yield put(footerLinksLoadSuccess(response.data));
  } catch (error) {
    yield put(footerLinksFailure(error));
  }
}

export default all([takeLatest('@footerLinks/LOAD_REQUEST', fetchFooterLinks)]);
